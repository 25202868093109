<template>
  <v-card flat>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab>
        <v-icon left>mdi-package-variant-closed</v-icon>
        Inventario
      </v-tab>
      <v-tab>
        <v-icon left>mdi-cog</v-icon>
        General
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat class="pa-3">
          <Inventories
            v-if="warehouseIsSelected" 
            :warehouse="warehouse" 
            :active="tab === 0"
          />
          <div v-else class="px-8">
            <v-alert
              dense
              text
              type="info"
            >
              Seleccione un almacén
            </v-alert>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item eager>
        <General :warehouse="warehouse" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Inventories from '@/views/inventory/Inventories';
import General from '../management/General';
import { objectEmpty } from '@/constants/helpers';
export default {
  components: {
    General,
    Inventories,
  },
  props: {
    warehouse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    warehouseIsSelected() {
      return !objectEmpty(this.warehouse);
    },
  },
};
</script>