<template>
  <div>
    <v-treeview
      :items="warehouses"
      activatable
      dense
      class="warehouse__pointer"
      selection-type="independent"
      return-object
      @update:active="selectWarehouseActive"
      selected-color="primary"
      open-all
    >
      <!-- @update:open="selectWarehouseOpen" -->
      <template v-slot:prepend="{ item }">
        <v-icon left>{{ icons[item.type] }}</v-icon>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { WAREHOUSE_ICON } from '@/constants';
export default {
  props: {
    warehouses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: [],

      icons: WAREHOUSE_ICON,
    };
  },
  mounted() {
    // if (this.warehouses.length > 0) {
    //   this.selectWarehouseActive(this.warehouses);  
    // }
  },
  methods: {
    selectWarehouseActive(item) {
      const warehouse = Object.assign({}, item);
      this.$emit('input', warehouse[0]);
    },
    selectWarehouseOpen(item) {
      console.log(item);
    },
  },
};
</script>

<style scoped>
.warehouse__pointer {
  cursor: pointer;
}
</style>