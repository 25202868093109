<template>
  <v-card>
    <Loader :loading="loading" absolute />
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-form ref="form" onsubmit="return false;">
            <v-text-field
              v-model="name"
              label="Nombre"
            />
            <v-textarea
              v-model="description"
              label="Description"
              rows="3"
            />
          </v-form>
        </v-col>
        <v-col cols="12" md="6" sm="12"></v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        small
        @click="saveChanges"
      >
        Guardar Cambios
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import WarehouseServices from '@/store/services/warehouse/WarehouseServices';
import { objectEmpty } from '@/constants/helpers';
import { mapGetters } from 'vuex';
export default {
  props: {
    warehouse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,

      name: '',
      description: '',
    };
  },
  created() {
    if (!objectEmpty(this.warehouse)) {
      this.newWarehouse = Object.assing({}, this.warehouse);
    }
  },
  methods: {
    async saveChanges() {
      if (this.$refs.form.validate()) {
        try {
          const body = {
            name: this.name,
            description: this.description,
            main: this.warehouse.main,
            type: 'WAREHOUSE',
          }
          this.loading = true;
          await WarehouseServices.update(this.user.archon.id, this.warehouse.id, body);
          let warehouse = this.warehouse;
          warehouse.name = this.name;
          warehouse.description = this.description;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    warehouse: {
      deep: true,
      handler() {
        if (!objectEmpty(this.warehouse)) {
          const warehouse = Object.assign({}, this.warehouse);
          this.name = warehouse.name;
          this.description = warehouse.description;
        }
      },
    },
  },
}
</script>