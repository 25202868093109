<template>
  <v-card flat>
    <Loader :loading="loading" absolute />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="getInventories"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="inventories"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.sku="{ item }">
        <div>
          <div><b> {{ item.sku.sku }} </b></div>
          <small> {{ item.sku.description }} </small>
          <small>
            {{ item.sku.product.name }}
          </small>
        </div>
      </template>
      <template v-slot:item.price_cost="{ item }">
        <div> {{ parseFloat(item.price_cost).toFixed(2) }} </div>
      </template>
      <template v-slot:item.total="{ item }">
        <div> {{ getTotal(item) }} </div>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
  </v-card>
</template>

<script>
import InventoryServices from '@/store/services/inventory/InventoryServices';
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
export default {
  mixins: [ListMixin],
  props: {
    warehouse: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      inventories: [],
    };
  },
  created() {
    const warehouseId = this.warehouse?.id ?? 0;
    if (this.active && warehouseId !== 0) {
      this.getInventories();
    }
  },
  methods: {
    getTotal(item) {
      return (item.qty_physical * item.price_cost).toFixed(2);
    },
    async getInventories() {
      try {
        const params = {
          page: this.currentPage,
          order_by: 'created_at',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'sku.product',
        };
        this.loading = true;
        const response = await InventoryServices.index(this.user.archon.id, this.warehouse.id, params);
        this.inventories = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createProduct() {
      this.$router.push({
        name: 'productCreate',
      });
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getInventories();
      else this.currentPage = 1;
    },
    getBoxesUnits(inventory) {
      const physicalQty = inventory.qty_physical;
      const boxQty = inventory.sku.box_qty;
      let boxes = Math.trunc(parseInt(physicalQty) / parseInt(boxQty));
      let unist = parseInt(physicalQty) % parseInt(boxQty);
      return `${boxes}, ${unist} unid.`;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: 'Sku',
          sortable: false,
          value: 'sku',
        },
        { text: 'Cant. Fisica', value: 'qty_physical', sortable: false, },
        // { text: 'Cant. Danada', value: 'qty_hurt', sortable: false, },
        { text: 'Cant. Enviada', value: 'qty_sent', sortable: false, },
        // { text: 'Cant. Camino', value: 'qty_transit', sortable: false, },
        { text: 'Cant. Reservada', value: 'qty_reserved', sortable: false, },
        { text: 'Precio en almacen', value: 'price_cost', sortable: false, },
        { text: 'Total', value: 'total', sortable: false, },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getInventories();
    },
    warehouse() {
      const warehouseId = this.warehouse?.id ?? 0;
      if (this.active && warehouseId !== 0) {
        this.getInventories();
      }
    },
  },
}
</script>