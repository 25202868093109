<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div class="container-warehouses--list">
        <List
          v-model="currentWarehouse"
          :warehouses="warehouses"
        />
      </div>
      <div class="container-warehouses--configuration">
        <Management
          :warehouse="currentWarehouse"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import UserServices from '@/store/services/goliath/UserServices';
import WarehouseServices from '@/store/services/warehouse/WarehouseServices';
import List from './WarehousesList';
import Management from '../warehouse/WarehouseManagement';
import { mapGetters } from 'vuex';
export default {
  components: {
    List,
    Management,
  },
  data() {
    return {
      currentWarehouse: {},
      warehouses: [],

      loading: false,
    };
  },
  created() {
    this.getWarehouse();
  },
  methods: {
    async getWarehouse() {
      if ('warehouse' in this.user) {
        try {
          this.loading = true;
          const responseWarehouses = await WarehouseServices.index(this.user.archon.id);
          const warehouses = responseWarehouses.data.data
            .filter(warehouse => warehouse.parent_id >= this.user.warehouse.id)
          this.setWarehousesTree([...[this.user.warehouse], ...warehouses]);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    setWarehousesTree(warehouses) {
      const tree = [];
      const mapped = {};
      this.warehouses = [];
      warehouses.forEach((warehouse) => {
        if (warehouse.id !== null) {
          mapped[warehouse.id] = warehouse;
          warehouse.children = [];
        } 
      });
      warehouses.forEach((warehouse) => {
        if (warehouse.id === this.user.warehouse.id) {
          tree.push(warehouse);
          return;
        }
        if (typeof mapped[warehouse.parent_id] === 'undefined') {
          tree.push(warehouse);
        } else {
          mapped[warehouse.parent_id].children.push(warehouse);
        }
      });
      this.warehouses = tree;
      // this.key = Math.random();
    },
    // onCreatedCategory(category) {
    //   this.categories.push(category);
    //   const index = this.categories.length - 1;
    //   this.currentCategory = this.categories[index];
    //   this.model = index;
    //   this.currentEdit = true;
    //   this.visibleForm = true;
    // },
    // onDeletedCategory(category) {
    //   let index = this.findIndexCategory(category);
    //   this.categories.splice(index, 1);
    //   this.currentCategory = null;
    //   this.currentEdit = false;
    //   this.visibleForm = false;
    // },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
};
</script>

<style scoped>
.container-warehouses--list {
  width: 20em;
}
.container-warehouses--configuration {
  width: calc(100% - 20em);
}
</style>