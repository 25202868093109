<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <Loader :loading="loading" absolute />
      <v-card-title>
        <v-icon left>mdi-warehouse</v-icon>
        Nuevo Almacen
      </v-card-title>
      <v-card-text>
        <v-form ref="form" onsubmit="return false;">
          <v-text-field
            v-model="warehouse.name"
            label="Nombre"
            :rules="[isRequired]"
            :error-messages="errorName"
          />
          <v-textarea
            v-model="warehouse.description"
            label="Description"
            rows="3"
            :rules="[isRequired]"
          />
          <v-select
            v-model="warehouse.type"
            label="Tipo"
            :items="types"
            item-text="name"
            item-value="type"
            :rules="[isRequired]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="closeDialog"
          color="gray lighten-1"
          small
        >
          Cerrar
        </v-btn>
        <v-btn
          @click="storeWarehouse"
          color="success"
          small
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WarehouseServices from '@/store/services/warehouse/WarehouseServices';
import { isGreaterThan } from '@/constants/rules';
import { mapGetters } from 'vuex';
export default {
  props: {
    currentWarehouse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,

      warehouse: {
        name: '',
        description: '',
        main: false,
        type: 'WAREHOUSE',
        parent_id: 0,
      },

      types: [
        {
          name: 'Almacen',
          type: 'WAREHOUSE',
        },
        {
          name: 'Tienda',
          type: 'STORE',
        },
        {
          name: 'Movil',
          type: 'MOVIL',
        },
      ],
      address: {},
      contact: {},

      // errors
      errorName: '',

      // rules
      isRequired: value => !!value || 'Este campo es requerido',
      isGreaterThan0: value => isGreaterThan(0, value) || 'Este campo es invalido',
    };
  },
  methods: {
    async storeWarehouse() {
      if (this.$refs.form.validate()) {
        try {
          this.errorName = '';
          this.loading = true;
          this.warehouse.parent_id = this.currentWarehouse?.id ?? 0;
          const response = await WarehouseServices.store(this.user.archon.id, this.warehouse);
          let warehouse = response.data;
          warehouse['children'] = [];
          this.$emit('onAddWarehouse', {
            warehouse,
          });
          this.loading = false;
          this.closeDialog();
        } catch (error) {
          console.log(error);
          this.loading = false;
          if (error.response.status === 422) {
            const errorName = error.response.data.errors?.name[0] || null;
            if (errorName) {
              this.errorName = errorName;
            }
          }
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        this.resetData();
      }, 1000);
    },
    resetData() {
      this.warehouse = {
        name: '',
        description: '',
        main: false,
        type: 'WAREHOUSE',
        parent_id: 0,
      };
      this.address = {};
      this.contact = {};
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>