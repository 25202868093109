<template>
  <v-card flat>
    <v-btn
      color="primary"
      small
      block
      :elevation="0"
      large
      tile
      @click="newWarehouse"
      :disabled="objectEmpty(currentWarehouse)"
    >
      <v-icon left>mdi-plus</v-icon>
      Almacen
    </v-btn>
    <WarehouseTree
      :key="refTree"
      v-model="currentWarehouse"
      :warehouses="warehouses"
    />
    <WarehouseCreate
      ref="warehouseCreate"
      :current-warehouse="currentWarehouse"
      @onAddWarehouse="onAddWarehouse"
    />
  </v-card>
</template>

<script>
import WarehouseCreate from './WarehouseCreate';
import WarehouseTree from './WarehouseTree';
// helpers
import { objectEmpty, uuid } from '@/constants/helpers';
export default {
  components: {
    WarehouseCreate,
    WarehouseTree,
  },
  props: {
    warehouses: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.warehouses.length === 0) {
      this.currentWarehouse = {
        id: 0,
      };
    }
  },
  data() {
    return {
      currentWarehouseIndex: 0,
      currentWarehouse: {},

      objectEmpty,

      refTree: uuid(),
    };
  },
  methods: {
    newWarehouse() {
      const warehouseCreate = this.$refs['warehouseCreate'];
      warehouseCreate.dialog = true;
    },
    onAddWarehouse(data) {
      if (this.currentWarehouse.id === 0) {
        let warehouses = this.warehouses;
        let newWarehouse = Object.assign({}, data.warehouse);
        newWarehouse['children'] = [];
        warehouses.push(newWarehouse);
        this.currentWarehouse = data.warehouse;
        this.currentWarehouse['children'] = [];
      } else this.currentWarehouse['children'].push(data.warehouse);
      this.refTree = uuid();
    },
  },
  watch: {
    currentWarehouse() {
      this.$emit('input', this.currentWarehouse);
    },
  },
}
</script>